export { isDefined, trataCodigo, isVazia, removeCaracteresEspeciais, retiraAcento };

function isDefined(value) {
    return value !== null && value !== undefined && value !== isNaN;
}

function trataCodigo(value) {
    return String(value).replace('#', '');
}

function isVazia(value) {
    return String(value).trim().replace(/ /g, '') === "";
}

function removeCaracteresEspeciais(value) {
    return String(value).replace(/[^a-zA-Z ]/g, "");
}

function retiraAcento(str)  {
    let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let novastr="";
    for(let i=0; i<str.length; i++) {
        let troca=false;
        for (let a=0; a<com_acento.length; a++) {
            if (str.substr(i,1)==com_acento.substr(a,1)) {
                novastr+=sem_acento.substr(a,1);
                troca=true;
                break;
            }
        }
        if (troca==false) {
            novastr+=str.substr(i,1);
        }
    }
    return novastr;
}    