import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {Seller} from '../models/seller.models';
import {Pagination} from '../models/pagination.models';
import {trataCodigo} from 'src/app/helpers/utils';
import {StatusSellerQtdeDto} from '../dto/statusSellerQtdeDto';
import {SellerUpdateDadosIniciaisDto} from '../dto/seller-update-dados-iniciais-dto';
import {SellerUpdateDadosGeraisDto} from '../dto/seller-update-dados-gerais-dto';

@Injectable({providedIn: 'root'})
export class SellerService {

    private readonly baseUrl = `${environment.api}/seller`;

    constructor(private http: HttpClient) {
    }

    public getAll(): Observable<Array<Seller>> {
        return this.http.get<Array<Seller>>(`${this.baseUrl}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getAllAtivoOrAtivoPendente(): Observable<Array<Seller>> {
        return this.http.get<Array<Seller>>(`${this.baseUrl}/ativoOrAtivoPendente`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getById(id: number): Observable<Seller> {
        return this.http.get<Seller>(`${this.baseUrl}/${id}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public verifyIntegracaoFasfox(email: string, cpf: string, cnpj: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/verifyIntegracaoFasFox?email=${email}&cpf=${cpf}&cnpj=${cnpj}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getByCodigo(codigo: string): Observable<Seller> {
        return this.http.get<Seller>(`${this.baseUrl}/${codigo}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    ativarNovoSeller(idSeller: number, idUsuario: number, idPlataforma: number, taxaAdministracao: number): Observable<Seller> {
        return this.http.get<Seller>(`${this.baseUrl}/ativarNovoSeller/${idSeller}/${idUsuario}/${idPlataforma}/${taxaAdministracao}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getBySellerUsuario(idUsuario: number): Observable<Array<Seller>> {
        return this.http.get<Array<Seller>>(`${this.baseUrl}/sellerUsuario/${idUsuario}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getByUsuarioAndSeller(codigoSeller: string, codigoUsuario: string): Observable<Seller> {
        let codigoSellerTratado = trataCodigo(codigoSeller);
        let codigoUsuarioTratado = trataCodigo(codigoUsuario);
        return this.http.get<Seller>(`${this.baseUrl}/sellerFind/codigo/${codigoSellerTratado}/usuario/${codigoUsuarioTratado}`)
            .pipe(take(1));
    }

    public create(seller: Seller): Observable<Seller> {
        return this.http.post<Seller>(this.baseUrl, seller)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public update(id: number, seller: Seller): Observable<Seller> {
        return this.http.put<Seller>(`${this.baseUrl}/${id}`, seller)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public save(seller: Seller): Observable<Seller> {
        return this.http.post<Seller>(this.baseUrl, seller)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public executaAtivacaoSeller(sellerId: number): Observable<Seller> {
        return this.http.patch<Seller>(`${this.baseUrl}/${sellerId}/ativacao`, null)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public updateStatus(id: number, status: string): Observable<Seller> {
        return this.http.patch<Seller>(`${this.baseUrl}/${id}/status/${status}`, null)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public updateDadosIniciais(id: number, dto: SellerUpdateDadosIniciaisDto): Observable<Seller> {
        return this.http.patch<Seller>(`${this.baseUrl}/${id}/update-dados-iniciais`, dto)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public updateDadosGerais(id: number, dto: SellerUpdateDadosGeraisDto): Observable<Seller> {
        return this.http.patch<Seller>(`${this.baseUrl}/${id}/update-dados-gerais`, dto)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public deleteById(id: number): Observable<Seller> {
        return this.http.delete<Seller>(`${this.baseUrl}/${id}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getPageable(page: number, elements: number): Observable<Pagination<Seller>> {
        return this.http.get<Pagination<Seller>>(`${this.baseUrl}/page/${page}/elements/${elements}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getPageableFiltered(
        page: number,
        elements: number,
        idMarcaCnpj: string,
        status: string,
        tipo: string,
        dataInicial: string,
        dataFinal: string,
    ): Observable<Pagination<Seller>> {
        let pathString = `${this.baseUrl}/page/${page}/elements/${elements}/filter?`;
        if (status != null) {
            pathString += pathString.endsWith('?') ? `status=${status}` : `&status=${status}`;
        }
        if (idMarcaCnpj != null) {
            pathString += pathString.endsWith('?') ? `idMarcaCnpj=${idMarcaCnpj}` : `&idMarcaCnpj=${idMarcaCnpj}`;
        }
        if (tipo != null) {
            pathString += pathString.endsWith('?') ? `tipo=${tipo}` : `&tipo=${tipo}`;
        }
        if (dataInicial != null) {
            pathString += pathString.endsWith('?') ? `dataInicial=${dataInicial}` : `&dataInicial=${dataInicial}`;
        }
        if (dataFinal != null) {
            pathString += pathString.endsWith('?') ? `dataFinal=${dataFinal}` : `&dataFinal=${dataFinal}`;
        }
        return this.http.get<Pagination<Seller>>(
            // tslint:disable-next-line:max-line-length
            `${pathString}`
        ).pipe(tap(res => JSON.stringify(res)));
    }

    public getAllByOrderByUpdatedatDesc(page: number, elements: number): Observable<Pagination<Seller>> {
        return this.http.get<Pagination<Seller>>(`${this.baseUrl}/orderly/page/${page}/elements/${elements}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getAllSellerStatus(idUsuario: number): Observable<Array<Seller>> {
        return this.http.get<Array<Seller>>(`${this.baseUrl}/getAllSellerStatus/idUsuario/${idUsuario}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getStatusSellerQtde(): Observable<StatusSellerQtdeDto> {
        let pathString = `${this.baseUrl}/statusSellerQtde`;

        return this.http.get<StatusSellerQtdeDto>(pathString)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public gerarNovoApiKey(idSeller: number): Observable<string> {
        return this.http.get(`${this.baseUrl}/gerarNovoApiKey/idSeller/${idSeller}`, { responseType: 'text' })
            .pipe(tap(res => console.log(res)));
    }

    public getApiKey(idSeller: number): Observable<string> {
        return this.http.get(`${this.baseUrl}/getApiKey/idSeller/${idSeller}`, { responseType: 'text' })
            .pipe(tap(res => console.log(res)));
    }

}
