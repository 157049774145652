import { Component, OnInit, AfterViewInit, Input, OnChanges, ViewChild,  ElementRef, Injectable, OnDestroy} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import MetisMenu from 'metismenujs/dist/metismenujs';

import Scrollbar from 'smooth-scrollbar';

import { AuthenticationService } from '../../../core/services/authentication.service';
// import { AuthenticationService } from '../../../core/services/authentication.service';
import { User } from '../../../core/models/auth.models';
import { MENU } from '../config/menu-meta';
import { MenuItem } from '../models/menu.model';
import { activateMenuItems, resetMenuItems } from './utils';
import { UsuarioStatus } from 'src/app/core/models/enums/usuarioStatus.model';
import { CookieService } from 'src/app/core/services/cookie.service';

const URL_DASHBOARD = "/dashboard";
const URL_SELLER = "/dash-sellers";

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
@Injectable({ providedIn: 'root' })
export class LeftSidebarComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() includeLogo: boolean;
  @Input() navClasses: string;
  @Input() includeUserProfile: boolean;
  @Input() isCondensed: boolean;
  @Input() isScrollable: boolean;

  loggedInUser: User;
  sidebarScrollRef: any;

  // declare menu items
  menuItems = [];
  menu: any;
  showForcaDeVendas = false;

  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

  constructor(private router: Router, private authService: AuthenticationService, private cookieService: CookieService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
      }
    });

    // this.loggedInUser = this.authService.currentUser();
  }

  redirectToDashboard() {
    if(this.authService.isAdmin()) {
      this.router.navigate([URL_DASHBOARD]);
      return;
    }
    this.router.navigate([URL_SELLER]);
  }

  ngOnInit() {
    this.initialize();
  }

  ngAfterViewInit() {
    // init scrollbar
    this._initScrollbar();
    // activate menu item
    this._initMenu();
  }


  /**
   * On prop change, look for layout settings
   */
  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this._initMenu();
      });
    } else if (this.menu) {
      this.menu.dispose();
    }

    if (this.isCondensed || this.isScrollable) {
      const elem: any = document.querySelector('#left-side-menu-container');
      Scrollbar.destroy(elem);
    } else {
      this._initScrollbar();
    }
  }

  /**
   * Initlizes the scrollbar
   */
  _initScrollbar() {
    const elem: any = document.querySelector('#left-side-menu-container');
    this.sidebarScrollRef = Scrollbar.init(elem);
  }

  /**
   * Activate the parent dropdown
   * TODO: This is hard-coded check - change to some common way
   */
  _activateMenuDropdown() {
    const activeClass = 'mm-active';
    const dropdownActiveClass = 'mm-show';

    resetMenuItems(activeClass, dropdownActiveClass);
    activateMenuItems(activeClass, dropdownActiveClass);
  }

  /**
   * Initizes metis menu
   */
  _initMenu() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  /**
   * Initilize
   */
  initialize(): void {
    const cookie  = this.authService.currentSession();

    this.showForcaDeVendas = false;

    if(this.authService.hasPermissionColaborador()) {
      this.menuItems = this.menuItems.concat(MENU.COLABORADOR);
    }  
    if (this.authService.hasPermissionAdmin() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.ADMIN);
    }   
    if (this.authService.hasPermissionSeller() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.SELLER);
    }
    if (this.authService.hasPermissionVendedor() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.VENDEDOR);
    }
    if (this.authService.hasPermissionPlataformaShopping() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.PLATAFORMA_SHOPPING);
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.children !== undefined ? item.children.length > 0 : false;
  }

  /**
   * Hides the menubar
   */
  hideMenu() {
    document.body.classList.remove('sidebar-enable');
  }

  redirectToLink(url : string){
    this.hideMenu();
    this.router.navigate([url]);
  }
}