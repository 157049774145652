import { Component, OnInit, Input, OnChanges, AfterViewInit } from '@angular/core';

import { MENU } from '../../shared/config/menu-meta';
import { activateMenuItems, resetMenuItems } from './utils';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { UsuarioStatus } from 'src/app/core/models/enums/usuarioStatus.model';


@Component({
  selector: 'app-horizontal-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnChanges, AfterViewInit {
  // declare menu items
  menuItems = [];

  @Input() showMobileMenu: boolean;
  @Input() navCssClasses: string;
  @Input() navContainerCssClasses: string;

  constructor(private authService: AuthenticationService ) { }

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges() {
    this._activateMenuFromUrl();
  }

  ngAfterViewInit() {
    this._activateMenuFromUrl();
  }

  /**
   * Activate menu from url
   */
  _activateMenuFromUrl() {
    // reset
    resetMenuItems();

    // activate
    activateMenuItems();
  }

  /**
   * Initilize
   */
  initialize(): void {
    const cookie  = this.authService.currentSession();

    if(this.authService.hasPermissionColaborador()) {
      this.menuItems = this.menuItems.concat(MENU.COLABORADOR);
    }
    if (this.authService.hasPermissionAdmin() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.ADMIN);
      this.showMobileMenu = false;
    } 
    if (this.authService.hasPermissionSeller() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.SELLER);
      this.showMobileMenu = false;
    }
    if (this.authService.hasPermissionVendedor() && cookie.usuario.status.toString() === UsuarioStatus[0]) {
      this.menuItems = this.menuItems.concat(MENU.VENDEDOR);
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasChildren(item: any) {
    return item.children !== undefined ? item.children.length > 0 : false;
  }

  /**
   * On menu click
   */
  onMenuClick(event: any) {
    const nextEl = event.target.nextSibling;
    if (nextEl && !nextEl.classList.contains('show')) {
      const parentEl = event.target.parentNode;
      if (parentEl) { parentEl.classList.remove('show'); }

      nextEl.classList.add('show');
    }
    return false;
  }

}
