import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {CookieService} from '../services/cookie.service';
import {Auth} from '../models/authentication.models';
import {Session} from '../models/session.models';
import {environment} from '../../../environments/environment';
import {SellerService} from './seller.service';
import {LocalStorageService} from 'angular-2-local-storage';
import {StorageModel} from '../models/storage.models';
import {Seller} from '../models/seller.models';
import {isDefined} from 'src/app/helpers/utils';
import {Colaborador} from '../models/colaborador.models';
import {Usuario} from '../models/usuario.models';
import {Roles} from '../models/enums/Roles.models';
import {Permissao} from '../models/permissao.models';
import {PermissaoService} from './permissao.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    api = `${environment.apiAuth}/authentication/parceiros`;
    routeAuthUsuario = `${environment.apiAuth}/usuario`;
    session: Session;
    storage: StorageModel;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private sellerService: SellerService,
        private localStorage: LocalStorageService,
        private permissaoService: PermissaoService) {
    }

    public currentSession(): Session {
        if (!this.session) {
            this.session = JSON.parse(this.cookieService.getCookie('currentSession'));
        }

        return this.session;
    }

    public currentStorage(): StorageModel {
        if (!this.storage) {
            this.storage = JSON.parse(this.localStorage.get('currentStorage'));
        }

        return this.storage;
    }

    login(auth: Auth): Observable<Session> {
        return this.http.post<Session>(this.api, auth)
            .pipe(tap(async session => {
                return session;
            }));
    }

    loginWithSubscribe(auth: Auth) {
        return new Promise<Session>((resolve, reject) => {
            this.login(auth).subscribe(async session => {
                this.storage = new StorageModel();
                const sellers = await this.getSellersBySession(session);
                const permissions = await this.getAllPermissionsBySession(session);
                this.storage.sellers = sellers;
                this.storage.permissoes = permissions;
                this.localStorage.set('currentStorage', JSON.stringify(this.storage));
                this.cookieService.setCookie('currentSession', JSON.stringify(session), 1);
                resolve(session);
            }, error => {
                resolve(null);
            });
        });
    }

    public backOnline() {
        return this.http.get<Boolean>(`${this.api}/backOnline`,)
            .pipe(tap(res => {
                if (isDefined(res)) {
                    return true;
                } else {
                    return false;
                }
            }));
    }

    async getAllPermissionsBySession(session: Session) {
        return new Promise<Array<Permissao>>((resolve, reject) => {
            this.permissaoService.getByUsuario(session.usuario.id).subscribe(res => {
                resolve(res);
            }, err => {
                resolve([]);
            });
        });
    }

    async getSellersBySession(session: Session) {
        return new Promise<Array<Seller>>((resolve, reject) => {
            this.sellerService.getBySellerUsuario(session.usuario.id).subscribe(res => {
                resolve(res);
            }, err => {
                resolve([]);
            });
        });
    }

    updateSellerSelecioando(seller: Seller) {
        this.storage = this.currentStorage();
        this.storage.sellerSelecionado = seller;
        this.localStorage.set('currentStorage', JSON.stringify(this.storage));
    }

    updateSellerSelecioandoProduto(seller: Seller) {
        this.storage = this.currentStorage();
        this.storage.sellerSelecionadoProduto = seller;
        this.localStorage.set('currentStorage', JSON.stringify(this.storage));
    }

    updateIsColaborador(value: boolean, colaborador: Colaborador) {
        this.storage = this.currentStorage();
        this.storage.isColaborador = value;
        this.storage.colaborador = colaborador;
        this.localStorage.set('currentStorage', JSON.stringify(this.storage));
    }

    updateUsuario(usuario: Usuario) {
        this.session = this.currentSession();
        this.session.usuario = usuario;
        this.cookieService.setCookie('currentSession', JSON.stringify(this.session), 1);
    }

    getSellerSelecionado(): Seller {
        return this.currentStorage().sellerSelecionado;
    }

    getColaboradorSelecionado() {
        return this.currentStorage().colaborador;
    }

    isColaborador() {
        return this.hasPermissionColaborador();
    }

    logout() {
        this.cookieService.deleteCookie('currentSession');
        this.localStorage.remove('currentStorage');
        this.session = null;
    }

    hasPermissionAdmin() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.ADMIN) !== undefined ? true : false;
    }

    hasPermissionColaborador() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.COLABORADOR) !== undefined ? true : false;
    }

    hasPermissionComercial() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.COMERCIAL) !== undefined ? true : false;
    }

    hasPermissionVendedor() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.VENDEDOR) !== undefined ? true : false;
    }

    hasPermissionPlataformaShopping() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.PLATAFORMA_SHOPPING) !== undefined ? true : false;
    }

    hasPermissionCliente() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.CLIENTE) !== undefined ? true : false;
    }

    hasPermissionSeller() {
        if (!isDefined(this.currentStorage())) {
            return false;
        }
        return this.currentStorage().permissoes.find(p => p.nome.toString() === Roles.SELLER) !== undefined ? true : false;
    }

    isAdmin() {
        return this.hasPermissionAdmin();
    }

    isSeller() {
        return this.hasPermissionSeller();
    }

    public verifyIfSamePassword(senha: string, idUsuario: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.routeAuthUsuario}/verifyPassword/senha/${senha}/usuario/${idUsuario}`)
            .pipe(tap(res => JSON.stringify(res)));
    }
}
