import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';

import { EventService } from '../../../core/services/event.service';
import {
  SIDEBAR_THEME_DARK, SIDEBAR_THEME_DEFAULT, SIDEBAR_THEME_LIGHT, SIDEBAR_WIDTH_CONDENSED,
  SIDEBAR_WIDTH_FIXED, SIDEBAR_WIDTH_SCROLLABLE
} from '../../shared/models/layout.model';

@Component({
  selector: 'app-vertical-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() isBoxed: boolean;
  @Input() isCondensed: boolean;
  @Input() isScrollable: boolean;
  @Input() sidebarTheme: string;
  @Input() sidebarType: string;

  @Input() demo: string;

  isInitCompleted: boolean;

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.isInitCompleted = false;
  }

  ngOnChanges() {
    if (this.isInitCompleted) {
      // boxed vs fluid
      if (this.isBoxed) {
        document.body.setAttribute('data-layout-mode', 'boxed');
      } else {
        document.body.removeAttribute('data-layout-mode');
      }

      const mainLogo = document.getElementById('side-main-logo');
      // left sidebar theme
      switch (this.sidebarTheme) {
        case SIDEBAR_THEME_LIGHT:
          document.body.setAttribute('data-leftbar-theme', 'light');
          if (mainLogo) {
            mainLogo.setAttribute('src', 'assets/images/logo-dark.png');
          }
          break;
        case SIDEBAR_THEME_DARK:
          document.body.setAttribute('data-leftbar-theme', 'dark');
          if (mainLogo) {
            mainLogo.setAttribute('src', 'assets/images/logo.png');
          }
          break;
        default:
          document.body.setAttribute('data-leftbar-theme', 'default');
          if (mainLogo) {
            mainLogo.setAttribute('src', 'assets/images/logo.png');
          }
          break;
      }

      // left sidebar type
      switch (this.sidebarType) {
        case SIDEBAR_WIDTH_CONDENSED:
          document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
          this.isCondensed = true;
          this.isScrollable = false;
          break;
        case SIDEBAR_WIDTH_SCROLLABLE:
          this.isScrollable = true;
          this.isCondensed = false;
          document.body.setAttribute('data-leftbar-compact-mode', 'scrollable');
          break;
        default:
          this.isCondensed = false;
          this.isScrollable = false;
          document.body.setAttribute('data-leftbar-compact-mode', 'fixed');
          break;
      }
    }
  }

  ngAfterViewInit() {
    this.isInitCompleted = true;
    document.body.removeAttribute('data-layout');
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    this.eventService.broadcast('showRightSideBar');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
  }
}
