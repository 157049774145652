export const MENU = {
    COLABORADOR: [
        {
            label: 'COMERCIAL',
            isTitle: true
        },
        {
            label: 'Dashboard',
            icon: 'uil-home-alt',
            link: '/dashboard'
        },
        {
            label: 'Lojistas',
            icon: 'uil-heart-alt',
            link: '/lojas/lista'
        },
        {
            label: '[CRM] Parceiros',
            icon: 'uil-shop',
            link: '/comercial/lista'
        },
        {
            label: 'Produtos Sellers',
            icon: 'uil-pricetag-alt',
            link: '/produtos/lista'
        },
    ],
    VENDEDOR: [
        {
            label: 'FORÇA DE VENDAS',
            isTitle: true
        },
        {
            label: 'Dashboard',
            icon: 'uil-home-alt',
            link: '/dash-vendedor'
        },
        {
            label: 'Links',
            icon: 'mdi mdi-share',
            link: '/forca-de-vendas-links-lista-vendedor'
        },
    ],
    PLATAFORMA_SHOPPING: [
        {
            label: 'molShops',
            isTitle: true
        },
        {
            label: 'Dashboard',
            icon: 'uil-home-alt',
            link: '/dash-vendedor'
        },
        {
            label: 'Carrinhos',
            icon: 'mdi mdi-cart',
            link: '/carrinhos/lista'
        },
        {
            label: 'Pedidos',
            icon: 'uil-cart',
            link: '/pedidos/lista'
        },
        {
            label: 'Lojas',
            icon: 'uil-shop',
            children: [
                {
                    label: 'Compradores',
                    link: '/lojas/lista'
                },
                {
                    label: 'Vendedores',
                    link: '/plataforma-sellers/lista'
                },
            ]
        },
        {
            label: 'Marketing',
            icon: 'mdi mdi-share',
            children: [
                {
                    label: 'Campanhas de desconto',
                    link: '/plataforma-sellers/lista'
                },
                {
                    label: 'Cupons de desconto',
                    link: '/plataforma-sellers/lista'
                },
                {
                    label: 'Histórico campo pesquisa',
                    link: '/plataforma-sellers/lista'
                },
                {
                    label: 'Engajamento produtos',
                    link: '/plataforma-sellers/lista'
                },
                {
                    label: 'Campanhas UTM',
                    link: '/plataforma-sellers/lista'
                },
            ]
        },
        {
            label: 'Financeiro',
            icon: 'uil-dollar-alt',
            children: [
                {
                    label: 'Relatórios de repasse',
                    link: '/vendedor-lista'
                },
                {
                    label: 'Taxas financeiras',
                    link: '/plataforma-sellers/lista'
                },
            ]
        },
        {
            label: 'Administrativo',
            icon: 'mdi mdi-cogs',
            children: [
                {
                    label: 'Banners',
                    link: '/administrativo/banners/lista'
                },
            ]
        },
    ],
    ADMIN: [
        {
            label: 'ADMIN',
            isTitle: true
        },
        {
            label: 'Dashboard',
            icon: 'uil-home-alt',
            link: '/dashboard'
        },
        {
            label: 'Carrinhos',
            icon: 'mdi mdi-cart',
            link: '/carrinhos/lista'
        },
        {
            label: 'Pedidos',
            icon: 'uil-cart',
            link: '/pedidos/lista'
        },
        {
            label: 'Produtos',
            icon: 'uil-pricetag-alt',
            link: '/produtos',
            children: [
                {
                    label: 'Listagem de produtos',
                    link: '/produtos/lista'
                },
                {
                    label: 'Cadastrar novo produto',
                    link: '/produtos/edita'
                },
            ]
        },
        {
            label: 'Clientes',
            icon: 'uil-heart-alt',
            link: '/clientes',
            children: [
                {
                    label: 'Usuários',
                    link: '/clientes/usuarios/lista'
                },
                {
                    label: 'Lojas',
                    link: '/clientes/lojas/lista'
                },
            ]
        },
        {
            label: 'Colaboradores',
            icon: 'uil-user-circle',
            link: '/colaboradores/lista'
        },
        {
            label: 'Afiliados',
            icon: 'uil-comment-plus',
            link: '/afiliados/lista'
        },
        {
            label: 'Financeiro',
            icon: 'uil-dollar-alt',
            link: '/financeiro',
            children: [
                {
                    label: 'Pedidos',
                    link: '/financeiro/pedidos/lista'
                },
                {
                    label: 'Solicitações de saque',
                    link: '/financeiro/solicitacao-saque/lista'
                },
                {
                    label: 'Acompanhamento de NFs-e',
                    link: '/financeiro/nfse/lista'
                },
                {
                    label: 'Taxas',
                    link: '/financeiro/taxas/lista'
                },
            ]
        },
        {
            label: '[CRM] Parceiros',
            icon: 'uil-shop',
            link: '/comercial/lista'
        },
        {
            label: 'Cupons de Desconto',
            icon: 'uil-ticket',
            link: '/cupom-desconto/lista'
        },
        {
            label: 'Análise de crédito',
            icon: 'uil-cart',
            link: '/credito/pedidos/lista'
        },
        {
            label: 'Perguntas e respostas',
            icon: 'uil-comment-question',
            link: '/perguntas'
        },
        {
            label: 'Taxas',
            icon: 'mdi mdi-cogs',
            link: '/credito/pedidos/lista'
        },
        {
            label: 'Administrativo',
            icon: 'mdi mdi-cogs',
            link: '/administrativo',
            children: [
                {
                    label: 'Configurações gerais',
                    link: '/administrativo/configuracoes-gerais'
                },
                {
                    label: 'Bancos',
                    link: '/administrativo/bancos'
                },
                {
                    label: 'Ajuda',
                    link: '/administrativo/ajuda/lista'
                },
                {
                    label: 'Banners',
                    link: '/administrativo/banners/lista'
                },
                {
                    label: 'CNAES',
                    link: '/administrativo/cnaes/lista'
                },
                {
                    label: 'Coleção',
                    link: '/administrativo/colecao/lista'
                },
                {
                    label: 'Cores',
                    link: '/administrativo/cores/lista'
                },
                {
                    label: 'Composição',
                    link: '/administrativo/composicao/lista'
                },
                {
                    label: 'Categorias',
                    link: '/administrativo/categorias'
                },
                {
                    label: 'Tecidos',
                    link: '/administrativo/tecidos/lista'
                },
                {
                    label: 'Medidas',
                    link: '/administrativo/medidas/lista'
                },
                {
                    label: 'Alça',
                    link: '/administrativo/alca/lista'
                },
                {
                    label: 'Tamanhos',
                    link: '/administrativo/tamanhos/lista'
                },
                {
                    label: 'Modelagem',
                    link: '/administrativo/modelagem/lista'
                },
                {
                    label: 'Lavagem',
                    link: '/administrativo/lavagem/lista'
                },
                {
                    label: 'Fecho',
                    link: '/administrativo/fecho/lista'
                },
                {
                    label: 'Gola',
                    link: '/administrativo/gola/lista'
                },
                {
                    label: 'Salto',
                    link: '/administrativo/salto/lista'
                },
                {
                    label: 'Cano',
                    link: '/administrativo/cano/lista'
                },
                {
                    label: 'Bico',
                    link: '/administrativo/bico/lista'
                },
                {
                    label: 'Fechamento',
                    link: '/administrativo/fechamento/lista'
                },
                {
                    label: 'Sola',
                    link: '/administrativo/sola/lista'
                }
            ]
        }
    ],
    SELLER: [
        {
            label: 'Seller Center',
            isTitle: true
        },
        {
            label: 'Resumo',
            icon: 'dripicons-to-do',
            link: '/resume'
        },
        {
            label: 'Dashboard',
            icon: 'uil-home-alt',
            link: '/dash-sellers'
        },
        // {
        //     label: 'Perguntas',
        //     icon: 'uil-comment-question',
        //     link: '/perguntas'
        // },
        {
            label: 'Pedidos',
            icon: 'uil-cart',
            link: '/ordens/lista'
        },
        {
            label: 'Chat',
            icon: 'uil-cart',
            link: '/messenger'
        },
        {
            label: 'Produtos',
            icon: 'uil-pricetag-alt',
            link: '/produtos',
            children: [
                {
                    label: 'Listagem de produtos',
                    link: '/produtos/lista'
                },
                {
                    label: 'Cadastrar novo produto',
                    link: '/produtos/edita'
                },
                {
                    label: 'Campanhas de desconto',
                    link: '/campanha-desconto/lista'
                },
                // {
                //     label: 'Ações em massa',
                //     link: '/acoes-em-massa'
                // },
                // {
                //     label: 'Importar por planilha',
                //     link: '/produtos/planilha'
                // },
            ]
        },
        {
            label: 'Financeiro',
            icon: 'uil-dollar-alt',
            link: '/financeiro',
            children: [
                {
                    label: 'Extrato de movimentações',
                    link: '/balance'
                },
                {
                    label: 'Dados bancários',
                    link: '/conta-bancaria'
                },
                {
                    label: 'Taxas e serviços',
                    link: '/taxas-servicos'
                },
                {
                    label: 'Métodos de pagamento',
                    link: '/metodos-de-pagamento'
                }
            ]
        },
        // {
        //     label: 'Força de vendas',
        //     icon: 'mdi mdi-share',
        //     link: '/administrativo',
        //     badge: {
        //         variant: 'primary',
        //         text: 'MolPro'
        //     },
        //     children: [
        //         {
        //             label: 'O que é',
        //             link: '/forca-de-vendas-explicacao'
        //         },
        //         {
        //             label: 'Planos',
        //             link: '/forca-de-vendas-planos'
        //         },
        //         {
        //             label: 'Vendedores',
        //             link: '/forca-de-vendas-vendedor-lista'
        //         },
        //         {
        //             label: 'Links',
        //             link: '/forca-de-vendas-links-lista'
        //         },
        //         {
        //             label: 'Clientes',
        //             link: '/lojas/lista'
        //         },
        //         {
        //             label: 'Configurações',
        //             link: '/forca-de-vendas-configuracoes'
        //         },
        //     ]
        // },
        // {
        //     label: 'Whatsapp',
        //     icon: 'mdi mdi-whatsapp',
        //     link: '/administrativo',
        //     badge: {
        //         variant: 'primary',
        //         text: 'MolPro'
        //     },
        //     children: [
        //         {
        //             label: 'O que é',
        //             link: '/forca-de-vendas-explicacao'
        //         },
        //         {
        //             label: 'Campanhas',
        //             link: '/whatsapp/campanha/lista'
        //         },
        //         {
        //             label: 'Contatos',
        //             link: '/whatsapp/contatos/lista'
        //         },
        //         {
        //             label: 'Tags',
        //             link: '/whatsapp/tag/lista'
        //         },
        //         {
        //             label: 'Contas',
        //             link: '/forca-de-vendas-vendedor-lista'
        //         },
        //     ]
        // },
        {
            label: 'Cupons de Desconto',
            icon: 'uil-ticket',
            link: '/cupom-desconto/lista'
        },
        // {
        //     label: 'Logística',
        //     icon: 'uil-ticket',
        //     link: '/cupom-desconto/lista'
        // },
        {
            label: 'Administrativo',
            icon: 'mdi mdi-cogs',
            link: '/administrativo',
            children: [
                {
                    label: 'Usuários',
                    link: '/usuarios'
                },
                {
                    label: 'Restrições',
                    link: '/restricoes'
                },
                {
                    label: 'Configurações gerais',
                    link: '/configuracoes-gerais-seller'
                },
                {
                    label: 'Guia de medidas',
                    link: '/guia-de-medidas-lista'
                },
                {
                    label: 'Integrações',
                    link: '/integracao/lista'
                },
                // {
                //     label: 'Chave API',
                //     link: '/chave-api/lista'
                // },
                // {
                //     label: 'Regras API',
                //     link: '/regras-api/lista'
                // },
                // {
                //     label: 'Regras API',
                //     link: '/regras-api/lista'
                // },
            ]
        },
    ],
    AFILIADOS: [
        {
            label: 'AFILIADOS',
            isTitle: true
        },
        {
            label: 'Dashboard',
            icon: 'uil-home-alt',
            link: '/dashboard-afiliados'
        },
        {
            label: 'Extrato de movimentações',
            link: '/balance'
        },
        {
            label: 'Dados bancários',
            link: '/conta-bancaria'
        },
    ],
    CONSULTOR: [
        {
            label: 'CONSULTOR',
            isTitle: true
        },
        // {
        //     label: 'Dashboard',
        //     icon: 'uil-home-alt',
        //     link: '/dashboard-afiliados'
        // },
        {
            label: 'Lojas',
            link: '/lojas/lista'
        },
    ]
};
