import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './core/guards/auth.guard';
import {LayoutContainerComponent} from './layouts/layout-container.component';

const routes: Routes = [
    {
        path: 'apps',
        component: LayoutContainerComponent,
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },
    {
        path: '',
        loadChildren: () => import('./account/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        component: LayoutContainerComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
