import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';

import { PortletComponent } from './portlet/portlet.component';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { SlimscrollDirective } from './slimscroll.directive';

@NgModule({
  declarations: [PortletComponent, DaterangepickerComponent, TimepickerComponent, SlimscrollDirective],
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule
  ],
  exports: [PortletComponent, DaterangepickerComponent, TimepickerComponent, SlimscrollDirective]
})
export class UIModule { }
