import { Colaborador } from './colaborador.models';
import { Permissao } from './permissao.models';
import { Seller } from './seller.models';

export class StorageModel {
    sellers?: Array<Seller>;
    sellerSelecionado: Seller;
    isColaborador: boolean = false;
    isSellerUsuario: boolean = false;
    colaborador: Colaborador;
    sellerSelecionadoProduto: Seller;
    permissoes : Array<Permissao> = [];
}
