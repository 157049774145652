import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Permissao } from '../models/permissao.models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PermissaoService {
    constructor(private http: HttpClient) { }

    permissaoRoute = `${environment.apiAuth}/permissao`;

    public getAll(): Observable<Array<Permissao>> {
        return this.http.get<Array<Permissao>>(`${this.permissaoRoute}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getById(id: number): Observable<Permissao> {
        return this.http.get<Permissao>(`${this.permissaoRoute}/${id}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public getByUsuario(id: number): Observable<Array<Permissao>> {
        return this.http.get<Array<Permissao>>(`${this.permissaoRoute}/usuario/${id}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public hasPermissaoColaboradorByUsuario(id: number): Observable<boolean> {
        return this.http.get<boolean>(`${this.permissaoRoute}/verifyColaborador/usuario/${id}`)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public save(permissao: Permissao): Observable<Permissao> {
        return this.http.post<Permissao>(this.permissaoRoute, permissao)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public update(id: number, permissao: Permissao): Observable<Permissao> {
        return this.http.put<Permissao>(`${this.permissaoRoute}/${id}`, permissao)
            .pipe(tap(res => JSON.stringify(res)));
    }

    public deleteById(id: number): Observable<Permissao> {
        return this.http.delete<Permissao>(`${this.permissaoRoute}/${id}`)
            .pipe(tap(res => JSON.stringify(res)));
    }
}
