// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    demo: 'saas', // other possible options are creative and modern
    api: 'https://parceiros.modaonline.com.br',
    apiMarketplace: 'https://mktplc.modaonline.com.br',
    apiAuth: 'https://auth.modaonline.com.br',
    apiServices: 'https://services.modaonline.com.br',
    apiFile: 'https://file.modaonline.com.br',
    apiEmail: 'https://email.modaonline.com.br',
    apiPagamento: 'https://pagamento.modaonline.com.br',
    app_marketplace: 'https://modaonlinemarketplace.com.br/',

    // api: 'http://localhost:8080',
    // apiMarketplace: 'http://localhost:8081',
    // apiAuth: 'http://localhost:8082',
    // apiServices: 'http://localhost:8084',
    // apiFile: 'http://localhost:8085',
    // apiEmail: 'http://localhost:8087',
    // apiPagamento: 'http://localhost:8086',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
