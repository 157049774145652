import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {LocalStorageModule} from 'angular-2-local-storage';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {ToastrModule, ToastrService} from 'ngx-toastr';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ErrorInterceptor} from './core/helpers/error.interceptor';
import {JwtInterceptor} from './core/helpers/jwt.interceptor';
import {FakeBackendProvider} from './core/helpers/fake-backend';
import {OnlyNumberDirective} from './core/directives/onlyNumbers.directive';
import {OnlyNumberDirective as OnlyNumberDirective2} from './helpers/onlyNumbers.directive';
import {LayoutsModule} from './layouts/layouts.module';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        AppComponent,
        OnlyNumberDirective,
        OnlyNumberDirective2
    ],
    imports: [
        LocalStorageModule.forRoot({
            storageType: 'localStorage'
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right'
        }),
        NgxDropzoneModule,
        BrowserModule,
        NgbModule,
        DragDropModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutsModule,
        AppRoutingModule,
        FormsModule,
        NgxPageScrollCoreModule.forRoot({duration: 200}),
        NgxPageScrollModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        Title,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        // provider used to create fake backend
        FakeBackendProvider,
        ToastrService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
