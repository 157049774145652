import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthenticationService} from '../../../core/services/authentication.service';
import {SellerService} from 'src/app/core/services/seller.service';
import {NotificationItem} from '../../shared/models/notification.model';
import {Seller} from 'src/app/core/models/seller.models';
import {Usuario} from '../../../core/models/usuario.models';


@Component({
    selector: 'app-vertical-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit {
    notificationItems: Array<NotificationItem>;
    sellers: Array<Seller>;
    selectedSeller: Seller;

    openMobileMenu: boolean;

    loggedInUser: Usuario;

    @Output() settingsButtonClicked = new EventEmitter();
    @Output() mobileMenuButtonClicked = new EventEmitter();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private sellerService: SellerService
    ) {
    }

    ngOnInit() {
        // get the notifications
        this._fetchNotifications();

        this.openMobileMenu = false;

        // logged in user
        this.loggedInUser = this.isDefined(this.authService.currentSession())
            ? this.authService.currentSession().usuario
            : null;

        this.selectedSeller = this.isDefined(this.authService.currentSession())
            ? this.authService.getSellerSelecionado()
            : null;


        if (this.loggedInUser !== null) {
            this.sellerService.getAllSellerStatus(this.loggedInUser.id)
                .subscribe(sellers => {
                    this.sellers = sellers;
                });
        }


    }

    isDefined(value) {
        return value !== null && value !== undefined;
    }

    ngAfterViewInit() {
    }

    /**
     * Fetches the notification
     * Note: For now returns the hard coded notifications
     */
    _fetchNotifications() {
        this.notificationItems = [{
            text: 'Caleb Flakelar commented on Admin',
            subText: '1 min ago',
            icon: 'mdi mdi-comment-account-outline',
            bgColor: 'primary',
            redirectTo: '/notification/1'
        },
            {
                text: 'New user registered.',
                subText: '5 min ago',
                icon: 'mdi mdi-account-plus',
                bgColor: 'info',
                redirectTo: '/notification/2'
            },
            {
                text: 'Cristina Pride',
                subText: 'Hi, How are you? What about our next meeting',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'success',
                redirectTo: '/notification/3'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '2 days ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'danger',
                redirectTo: '/notification/4'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '1 min ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'primary',
                redirectTo: '/notification/5'
            },
            {
                text: 'New user registered.',
                subText: '5 min ago',
                icon: 'mdi mdi-account-plus',
                bgColor: 'info',
                redirectTo: '/notification/6'
            },
            {
                text: 'Cristina Pride',
                subText: 'Hi, How are you? What about our next meeting',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'success',
                redirectTo: '/notification/7'
            },
            {
                text: 'Caleb Flakelar commented on Admin',
                subText: '2 days ago',
                icon: 'mdi mdi-comment-account-outline',
                bgColor: 'danger',
                redirectTo: '/notification/8'
            }];
    }

    selectSeller(seller) {
        const sellerAnterior = this.authService.currentStorage().sellerSelecionado;

        this.sellerService.executaAtivacaoSeller(seller.id)
            .subscribe(
                result => {
                    this.selectedSeller = result;
                    this.authService.updateSellerSelecioando(result);

                    if (result.status.toString() === 'ATIVO_PENDENTE') {
                        this.router.navigateByUrl(`/resume`);
                    } else if (result.status.toString() === 'ATIVO_NOVA_LOJA') {
                        this.router.navigateByUrl(`/nova-loja`);
                    } else if (result.status.toString() === 'ATIVO') {
                        this.router.navigateByUrl(`/dash-sellers`);
                    }
                    setTimeout(()=>{
                        location.reload();
                    },200);
                },
                error => console.log('erro ao verificar status do seller selecionado')
            );
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    isColaborador() {
        return this.authService.isColaborador();
    }

    isSeller() {
        return this.authService.hasPermissionSeller();
    }

    /**
     * Toggles the right sidebar
     */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Logout the user
     */
    logout() {
        this.authService.logout();
        this.router.navigate(['/']);
    }
}
