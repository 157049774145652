import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { VerticalLayoutModule } from './vertical/vertical.module';
import { HorizontalLayoutModule } from './horizontal/horizontal.module';
import { DetachedLayoutModule } from './detached/detached.module';

import { SharedModule } from './shared/shared.module';
import { LayoutContainerComponent } from './layout-container.component';
import { AcessoRapidoLayoutModule } from './acesso-rapido/acesso-rapido.module';


@NgModule({
  declarations: [LayoutContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    VerticalLayoutModule,
    HorizontalLayoutModule,
    DetachedLayoutModule,
    AcessoRapidoLayoutModule
  ]
})
export class LayoutsModule { }
